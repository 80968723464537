<template>
  <ion-page>
  <ContentContainer>
    <div class="max-w-xl mx-auto">
      <Refresher @refresh="doRefresh" />

      <div v-if="user">
        <AdvertisementSlides ad-slot="profile_page" margin-bottom />
        <PageItem>
          <div class="flex">
            <ion-avatar slot="start">
              <img :src="`${$apiStorageUrl}/${user.profile_image}`">
            </ion-avatar>

            <div class="ml-2">
              <ion-text><span>{{ user.first_name }} {{ user.last_name }}</span></ion-text>
            </div>
          </div>

          <div>
            <ion-button size="small" expand="block" color="moto" class="mt-2 flex-1" @click="logout">
              {{ $t('logout') }}
            </ion-button>
          </div>
        </PageItem>

        <ion-list class="mt-4" lines="full">
          <ion-item v-if="user && user.facilities && user.facilities.length" button @click="$router.push({ name: $routeNames.MANAGED_FACILITIES })">
            <ion-label>{{ $t('facilities') }}</ion-label>
          </ion-item>
          <ion-item button @click="$router.push({ name: $routeNames.PROFILE_ORDERS })">
            <ion-label>{{ $t('orders') }}</ion-label>
          </ion-item>
          <ion-item button @click="$router.push({ name: $routeNames.PROFILE_CHANGE_INFORMATION })">
            <ion-label>{{ $t('changeinformation') }}</ion-label>
          </ion-item>
        </ion-list>
      </div>
    </div>
  </ContentContainer>
  </ion-page>
</template>

<script>
    import PageItem from '@/components/PageItem'
    import { logoutAndRedirect } from "@/utils/logoutAndRedirect";
    import { mapGetters } from "vuex";
    import { CURRENT_USER_GETTER } from "@/store/store-getters";
    import AdvertisementSlides from "../components/AdvertisementSlides";

    export default {
      components: {
        AdvertisementSlides,
        PageItem
      },
      computed: {
        ...mapGetters({user: CURRENT_USER_GETTER}),
      },
      methods: {
        doRefresh(event) {
          event.target.complete()
        },
        logout() {
          return logoutAndRedirect()
        }
      }
    }
</script>
